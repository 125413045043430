import * as React from "react"
import Seo from "../components/SEO"
import styled from "styled-components"
import Button from "../components/Button"
import Banner from "../components/Banner"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { Trans, useTranslation } from "react-i18next"
import { useI18next } from "gatsby-plugin-react-i18next"

const Form = styled.div`
  margin: calc(var(--spacing) * 2) 0;

  @media (min-width: 1200px) {
    margin: calc(var(--spacing) * 4) 0;
  }
`

const FormLabel = styled.label`
  font-family: var(--serif);
  font-size: var(--h5);
  margin-bottom: var(--spacing);
  display: inline-block;
`

const FormInput = styled.input`
  height: 50px;
  width: 100%;
  margin-bottom: calc(var(--spacing) * 1.5);
  font-size: var(--para);
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.25);
`

const FormTextArea = styled.textarea`
  width: 100%;
  min-height: 150px;
  margin-bottom: calc(var(--spacing) * 1.5);
  font-size: var(--para);
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.25);
`

/**
 * input names are from zoho and can not be changed!
 */
const ContactPage = ({data}) => {
	const { site } = data;
	const {defaultLanguage, language} = useI18next();
	const {t} = useTranslation();
	const baseUrl = defaultLanguage === language ?
		site.siteMetadata.siteUrl :
		`${site.siteMetadata.siteUrl}/${language}`;

	return (
		<>
			<Seo
				title={t("Get in touch")}
				description={t('Contact us with any idea or question you have')}
			/>
			<Layout>
				<Banner content="Get in touch today" />
				<p>
					<Trans>We will answer as soon as possible</Trans>
					{" "}
				</p>
				<Form>
					<form
						action='https://forms.zohopublic.eu/odania/form/Kontaktformular/formperma/0NtRA2W4OwqVn6uPdKpoYmqpMGeE1b2ne013b35Ab5s/htmlRecords/submit'
						name='form'
						id='form'
						method='POST'
						acceptCharset='UTF-8'
						encType='multipart/form-data'
					>
						<input type="hidden" name="zf_referrer_name" value=""/>
						<input
							type="hidden"
							name="zf_redirect_url"
							value={`${baseUrl}/contact-success`}
						/>
						<input type="hidden" name="zc_gad" value=""/>
						<p>
							<FormLabel for="name">
								<Trans>Your Name</Trans>
							</FormLabel>
							<FormInput type="text" name="SingleLine" />
						</p>
						<p>
							<FormLabel for="email">
								<Trans>Email Address</Trans>
							</FormLabel>
							<FormInput type="email" name="SingleLine1" />
						</p>
						<p>
							<FormLabel for="email">
								<Trans>Subject</Trans>
							</FormLabel>
							<FormInput type="email" name="SingleLine2" />
						</p>
						<p>
							<FormLabel for="message">
								<Trans>Your Message</Trans>
							</FormLabel>
							<FormTextArea type="text" name="MultiLine" />
						</p>
						<p>
							<Button className="btn-link" text={t('Send Message')} type="submit" />
						</p>
					</form>
				</Form>
			</Layout>
		</>
	);
}

export default ContactPage;

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
